import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Button } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { InlineList } from 'src/components/molecules/InlineList';
import { Badge } from 'src/components/atoms/Badge';
import { Shuffler } from 'src/components/atoms/Shuffler';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => children;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section maxWidth="large" secondary centered mdxType="Section">
      <SectionHeader mdxType="SectionHeader">
        <h2 {...{
          "id": "research-topics"
        }}>{`Research topics`}</h2>
        <p>{`These are just some of the topics we're working on.`}<br />{` The very curious can directly look at our active branches on `}<a parentName="p" {...{
            "href": "https://github.com/RasaHQ/rasa/branches"
          }}>{`GitHub`}</a>{`.`}</p>
      </SectionHeader>
      <Grid mdxType="Grid">
        <Card mdxType="Card">
          <Badge secondary margin mdxType="Badge">
  Shipped in Rasa 2.2
          </Badge>
          <h3 {...{
            "id": "incremental-training"
          }}>{`Incremental Training`}</h3>
          <p>{`We've added support for our model pipelines to support incremental training. This
allows you to fine-tune an existing model after adding new training examples instead of
training a new model from scratch. It's an ongoing area of research on how to do this
effectively, but our first results look promising`}</p>
          <InlineList mdxType="InlineList">
  <li>
    <Button to="https://www.youtube.com/watch?v=FipRjQRaCz8&list=PL75e0qA87dlG-za8eLI6t0_Pbxafk-cxb&index=36" mdxType="Button">Explainer Video</Button>
  </li>
  <li>
    <Button to="/blog/rasa-new-incremental-training/" secondary mdxType="Button">
      Read the Blogpost
    </Button>
  </li>
          </InlineList>
        </Card>
        <Card mdxType="Card">
          <h3 {...{
            "id": "whatlies-in-word-embeddings"
          }}>{`"Whatlies" in Word Embeddings`}</h3>
          <p><a parentName="p" {...{
              "href": "https://github.com/RasaHQ/whatlies/"
            }}>{`Whatlies`}</a>{` is an open source toolkit for visually inspecting word and sentence embeddings. The project offers a unified and extensible API with current support for a range of popular embedding backends including spaCy, tfhub, huggingface transformers, gensim, fastText and BytePair embeddings.`}</p>
          <InlineList mdxType="InlineList">
  <li>
    <Button to="https://arxiv.org/abs/2009.02113" mdxType="Button">Read the Paper</Button>
  </li>
  <li>
    <Button to="https://rasahq.github.io/whatlies/" secondary mdxType="Button">
      Project Docs
    </Button>
  </li>
          </InlineList>
        </Card>
        <Card mdxType="Card">
          <Badge secondary margin mdxType="Badge">
  Shipped in Rasa 1.8
          </Badge>
          <h3 {...{
            "id": "dual-intent-and-entity-transformer-diet"
          }}>{`Dual Intent and Entity Transformer (DIET)`}</h3>
          <p>{`DIET is a new state of the art NLU architecture that jointly predicts intents and entities. It outperforms fine-tuning BERT and is 6x faster to train. You can use DIET together with BERT and other pre-trained language models in a plug-and-play fashion.`}</p>
          <Button to="https://www.youtube.com/watch?v=vWStcJDuOUk" mdxType="Button">Explainer Video</Button>
        </Card>
        <Card mdxType="Card">
          <Badge secondary margin mdxType="Badge">
  Shipped in Rasa 1.3
          </Badge>
          <h3 {...{
            "id": "dialogue-transformers"
          }}>{`Dialogue Transformers`}</h3>
          <p>{`What's the best data structure for dialogue memory - a stack? a graph? a flat list? Self-attention gives you great flexibility without a complex memory structure.`}</p>
          <Button to="https://arxiv.org/abs/1910.00486/" mdxType="Button">Read the paper</Button>
        </Card>
        <Card mdxType="Card">
          <h3 {...{
            "id": "transfer-learning-across-dialogue-tasks"
          }}>{`Transfer Learning Across Dialogue Tasks`}</h3>
          <p>{`You've built an assistant and it can already help users with a few things. Now you're adding new functionality. How can your assistant re-use the `}<a parentName="p" {...{
              "href": "/blog/dialogue-elements-for-conversational-ai/"
            }}>{`dialogue elements`}</a>{` it already knows about in this new context?`}</p>
          <InlineList mdxType="InlineList">
  <li>
    <Button to="/blog/attention-dialogue-and-learning-reusable-patterns/" mdxType="Button">Read the blog post</Button>
  </li>
  <li>
    <Button to="https://arxiv.org/abs/1811.11707" secondary mdxType="Button">
      NeurIPS 2018 Workshop Paper
    </Button>
  </li>
          </InlineList>
        </Card>
        <Card mdxType="Card">
          <h3 {...{
            "id": "compressing-transformer-language-models"
          }}>{`Compressing Transformer Language Models`}</h3>
          <p>{`Large-scale language models like BERT, GPT-2, and XLNet show excellent performance on a number of NLU tasks but are very resource intensive. Can we compress these models to get something that's almost as accurate but much faster?`}</p>
          <Button to="/blog/compressing-bert-for-faster-prediction-2/" mdxType="Button">Read about Quantizing BERT</Button>
        </Card>
        <Card mdxType="Card">
          <Badge secondary margin mdxType="Badge">
  Shipped in Rasa 1.2
          </Badge>
          <h3 {...{
            "id": "entity-resolution-using-knowledge-bases"
          }}>{`Entity Resolution using Knowledge Bases`}</h3>
          <p>{`Combining a dialogue system with a knowledge base allows developers to encode domain knowledge in a scalable way and integrate it with statistical NLU and dialogue models. It also helps your assistant understand messages like `}<em parentName="p">{`the second one`}</em>{` or `}<em parentName="p">{`which of those is cheaper?`}</em>{` .`}</p>
          <InlineList mdxType="InlineList">
  <li>
    <Button to="/blog/integrating-rasa-with-knowledge-bases/" mdxType="Button">Read the Tutorial</Button>
  </li>
  <li>
    <Button to="https://github.com/RasaHQ/tutorial-knowledge-base" secondary mdxType="Button">
      View the Code
    </Button>
  </li>
          </InlineList>
        </Card>
        <Card mdxType="Card">
          <Badge secondary margin mdxType="Badge">
  Shipped in Rasa NLU 0.12
          </Badge>
          <h3 {...{
            "id": "supervised-word-embeddings"
          }}>{`Supervised Word Embeddings`}</h3>
          <p>{`Pre-trained word embeddings like word2vec and GloVe are a great way to build a simple text classifier. But learning supervised embeddings for your specific task helps you deal with jargon and out-of-vocabulary words. This is now our default intent classification model.`}</p>
          <InlineList mdxType="InlineList">
  <li>
    <Button to="/blog/supervised-word-vectors-from-scratch-in-rasa-nlu/" mdxType="Button">Read the blog post</Button>
  </li>
  <li>
    <Button to="/docs/rasa/nlu/choosing-a-pipeline/" secondary mdxType="Button">
      Docs
    </Button>
  </li>
          </InlineList>
        </Card>
        <Card mdxType="Card">
          <Badge secondary margin mdxType="Badge">
  Shipped in Rasa 1.3
          </Badge>
          <h3 {...{
            "id": "mixing-single-and-multi-turn-dialogue"
          }}>{`Mixing Single and Multi-turn Dialogue`}</h3>
          <p><a parentName="p" {...{
              "href": "/docs/rasa/dialogue-elements/dialogue-elements/"
            }}>{`Dialogue elements`}</a>{` like small talk and FAQs are single-turn interactions. New retrieval-based models in Rasa can handle all of these simple responses in a single action. This means your dialogue policy becomes much simpler and you need fewer training stories.`}</p>
          <Button to="/blog/response-retrieval-models/" mdxType="Button">Read the blog post</Button>
        </Card>
        <Card mdxType="Card">
          <Badge secondary margin mdxType="Badge">
  Work in Progress
          </Badge>
          <h3 {...{
            "id": "conversational-embeddings"
          }}>{`Conversational Embeddings`}</h3>
          <p>{`Most language models and word embeddings are trained on prose and don't know anything about the rules of conversation. How can we build embeddings that understand the difference between purposeful dialogue and chit-chat, and can detect non-sequiturs?`}</p>
        </Card>
        <Card mdxType="Card">
          <h3 {...{
            "id": "talks-and-meetups"
          }}>{`Talks and Meetups`}</h3>
          <p>{`We regularly host external speakers at our #botsBerlin meetup to talk about their research.`}</p>
          <InlineList mdxType="InlineList">
  <li>
    <Button to="https://www.youtube.com/channel/UCJ0V6493mLvqdiVwOKWBODQ" primary mdxType="Button">
      See Our YouTube Channel
    </Button>
  </li>
          </InlineList>
        </Card>
      </Grid>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      